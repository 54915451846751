import React, { useEffect, useState } from 'react';
import './AddAuditorForm.css'; // Import the CSS file
import axios from 'axios';
import { Typography, Col, Row, Alert, Table, TableProps, Card } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useSearchParams } from 'react-router-dom';
import { SearchOutlined, FrownTwoTone, MehTwoTone, SmileTwoTone } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import dayjs from '../../../utils/dayjs';
// import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import styles from './style.module.css';
import { CountryList } from './Location';
import AuditorTableOptions from './AuditorTableOptions';
import AuditorTable from '../AuditorTable';
import { auditor as auditorApi } from '@rap/api';
import { last } from 'lodash';

interface IAuditorTableProps {
  isLoading: boolean;
  auditorList: rateauditor.api.auditor.IAuditorInfo[];
  searchParams: URLSearchParams;
  setSearchParams: any;
}

interface AuditorForm {
  first_name: string;
  last_name: string;
  firm_name: string;
  firm_issuing_country: string;
  firm_issuing_state: string;
  firm_issuing_city: string;
  linkedin_profile: string;
  note: string;
  creater: string;
  role: string;
}

const AddAuditorForm: React.FC = () => {
  const [form, setForm] = useState<AuditorForm>({
    first_name: '',
    last_name: '',
    firm_name: '',
    firm_issuing_country: '',
    firm_issuing_state: '',
    firm_issuing_city: '',
    linkedin_profile: '',
    note: '',
    creater: '',
    role: 'Audit Partner',
  });

  const roles = ['Staff Accountant', 'Senior Staff Accountant', 'Manager', 'Senior Manager', 'Audit Partner'];
  const [isLoading, setLoading] = useState(true);
  const [auditorList, setAuditorList] = useState<rateauditor.api.auditor.IAuditorInfo[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [linkconfirmed, setLinkConfirmed] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(true); // State to control visibility of the info text
  const [submittedAuditors, setSubmittedAuditors] = useState<AuditorForm[]>([]);

  useEffect(() => {
    auditorApi.getAuditorList().then((res) => {
      setAuditorList(res.data.auditors);
      setLoading(false);
    });
    const storedAuditors = localStorage.getItem('submittedAuditors');
    if (storedAuditors) {
      setSubmittedAuditors(JSON.parse(storedAuditors));
    }

  }, []);

  const handleSubmit = async (e: React.FormEvent) => {

    e.preventDefault();
    if (!confirmed) {
      alert('Please confirm that the information provided is accurate.');
      return;
    }

    const { first_name, last_name, firm_name} = form;
    let userFound = false;

    try {
      const auditor = {
        first_name: form.first_name,
        last_name: form.last_name,
        firm_name: form.firm_name,
        firm_issuing_country: form.firm_issuing_country,
        firm_issuing_state: form.firm_issuing_state,
        firm_issuing_city: form.firm_issuing_city,
        linkedin: form.linkedin_profile,
        note: form.note,
        role: form.role,
      }
      for (let auditor of auditorList) {
        if (auditor.first_name === first_name && auditor.last_name === last_name) {
          console.log('Auditor found:', firm_name, auditor.firm_name);
          if(auditor.firm_name === firm_name){
          userFound = true;
          break;}
        }
      }

      if (userFound) {
        alert(`Auditor ${first_name} ${last_name} is already present in our database, please check the information and try again.`);
        return;
      }
      else {
        const res = await auditorApi.addAuditor(auditor).then(async (res) => {
          if (linkconfirmed) {
            const audID = res.data.result._id
            const claim = await auditorApi.claimAuditorProfile(audID).then((claim) => {
              console.log('done')
            })
          }

          location.reload()
        });
        alert('We have successfully added the auditor to the database. Thank you for your contribution!\n\n' + 'First Name: ' + form.first_name + '\n' + 'Last Name: ' + form.last_name + '\n' + 'Firm Name: ' + form.firm_name + '\n' + 'Firm Country: ' + form.firm_issuing_country + '\n' + 'Firm State: ' + form.firm_issuing_state + '\n' + 'Firm City: ' + form.firm_issuing_city + '\n' + 'LinkedIn Profile: ' + form.linkedin_profile + '\n' + 'Note: ' + form.note + '\n\n' + 'Once the auditor has been approved by a member of our team, the auditor profile will be accessible from the browse page!');
        //window.location.href = '/';
      }

    } catch (error : any) {
      console.error(error);
      if (error.response.status === 500) {
        alert('Please Login or Create an Account to Add an Auditor')
      }
      else {
        alert('An error occurred while adding auditor');
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const handleLinkCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>)=> {
    setLinkConfirmed(e.target.checked)
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <h2 className="header">Add Auditor</h2>
        <p className="subtitle">Please provide the following information:</p>

        {showInfo && (
          <div className="info-container">
            <p className="info-text">
              Thank you for using this form to add auditors to our database. Your cooperation in
              filling out the required fields and ensuring the accuracy of the information is
              greatly appreciated. Once you submit the new auditor information, our team will
              carefully verify it to ensure its accuracy and completeness before adding it to our
              database. Your attention to detail is instrumental in maintaining the integrity of our
              records.
            </p>
            <button className="close-info-button" onClick={handleCloseInfo}>
              Close
            </button>
          </div>
        )}

        <div className="input-container">
          <label className="label-style">
            First Name:
            <input
              type="text"
              name="first_name"
              placeholder="Please enter first name..."
              value={form.first_name}
              onChange={handleChange}
              className="input-style"
              required
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            Last Name:
            <input
              type="text"
              name="last_name"
              placeholder="Please enter last name..."
              value={form.last_name}
              onChange={handleChange}
              className="input-style"
              required
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            Role:
            <select
              name="role"
              value={form.role}
              onChange={(e) => setForm({ ...form, role: e.target.value })}
              className="input-style"
              required
            >
              {roles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="input-container">
          <label className="label-style">
            Firm Name:
            <input
              type="text"
              name="firm_name"
              placeholder="Please enter firm name..."
              value={form.firm_name}
              onChange={handleChange}
              className="input-style"
              required
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            Firm Country:
            <input
              type="text"
              name="firm_issuing_country"
              placeholder="Please enter country name..."
              value={form.firm_issuing_country}
              onChange={handleChange}
              className="input-style"
              required
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            Firm State:
            <input
              type="text"
              name="firm_issuing_state"
              placeholder="Please enter state name..."
              value={form.firm_issuing_state}
              onChange={handleChange}
              className="input-style"
              required
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            Firm City:
            <input
              type="text"
              name="firm_issuing_city"
              placeholder="Please enter city name..."
              value={form.firm_issuing_city}
              onChange={handleChange}
              className="input-style"
              required
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            LinkedIn Profile:
            <input
              type="text"
              name="linkedin_profile"
              placeholder="Please enter LinkedIn profile link..."
              value={form.linkedin_profile}
              onChange={handleChange}
              className="input-style"
            />
          </label>
        </div>
        <div className="input-container">
          <label className="label-style">
            Note:
            <textarea
              name="note"
              placeholder="Additional notes..."
              value={form.note}
              onChange={handleChange}
              className="input-style"
            ></textarea>
          </label>
        </div>

        <div className="low-box">
          <div className="checkbox-container">
            <label className="checkbox-label-style">
              <input
                type="checkbox"
                checked={linkconfirmed}
                onChange={handleLinkCheckboxChange}
                className="checkbox-style"
              />
              Link this Auditor Profile to my account
            </label>
          </div>
        </div>

        <div className="low-box">
          <div className="checkbox-container">
            <label className="checkbox-label-style">
              <input
                type="checkbox"
                checked={confirmed}
                onChange={handleCheckboxChange}
                className="checkbox-style"
                required
              />
              I confirm that the information provided is accurate.
            </label>
          </div>
        </div>
        <div className="low-box">
          <button type="submit" className="submit-button-style">
            Add Auditor
          </button>
        </div>
      </form>
    </>
  )
};

export default AddAuditorForm;
